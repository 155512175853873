import { default as _91articleId_93IiW3RT3FbSMeta } from "/node-app/pages/articles/[articleId].vue?macro=true";
import { default as indexVpGpbjLkYfMeta } from "/node-app/pages/debug/index.vue?macro=true";
import { default as _91categoryId_93z7TI3GQvd2Meta } from "/node-app/pages/feed/categories/[categoryId].vue?macro=true";
import { default as indexMlv5xagFasMeta } from "/node-app/pages/feed/index.vue?macro=true";
import { default as indexAXIJC9SXblMeta } from "/node-app/pages/index.vue?macro=true";
import { default as indexxrHRBZgZ4PMeta } from "/node-app/pages/login/index.vue?macro=true";
import { default as refreshWFn7h9l71mMeta } from "/node-app/pages/login/refresh.vue?macro=true";
import { default as indexZXtfF7TDLjMeta } from "/node-app/pages/more/ai-authors/index.vue?macro=true";
import { default as indexwYed8Pog2PMeta } from "/node-app/pages/more/ai-topics/index.vue?macro=true";
import { default as indexMdWTfg0af3Meta } from "/node-app/pages/more/authors/index.vue?macro=true";
import { default as indexVKnKQ6nCXyMeta } from "/node-app/pages/more/categories/index.vue?macro=true";
import { default as indexuaUnhxD5cVMeta } from "/node-app/pages/more/index.vue?macro=true";
import { default as indexnL9QlTXQ1OMeta } from "/node-app/pages/more/magazines/index.vue?macro=true";
import { default as indexX7IrHygKUQMeta } from "/node-app/pages/profile/index.vue?macro=true";
import { default as _91categoryId_934lOBwgISPQMeta } from "/node-app/pages/reader/categories/[categoryId].vue?macro=true";
import { default as indexoUEsPoJ6ymMeta } from "/node-app/pages/reader/index.vue?macro=true";
import { default as index98i5KceDvIMeta } from "/node-app/pages/trending/index.vue?macro=true";
export default [
  {
    name: _91articleId_93IiW3RT3FbSMeta?.name ?? "articles-articleId",
    path: _91articleId_93IiW3RT3FbSMeta?.path ?? "/articles/:articleId()",
    meta: _91articleId_93IiW3RT3FbSMeta || {},
    alias: _91articleId_93IiW3RT3FbSMeta?.alias || [],
    redirect: _91articleId_93IiW3RT3FbSMeta?.redirect,
    component: () => import("/node-app/pages/articles/[articleId].vue").then(m => m.default || m)
  },
  {
    name: indexVpGpbjLkYfMeta?.name ?? "debug",
    path: indexVpGpbjLkYfMeta?.path ?? "/debug",
    meta: indexVpGpbjLkYfMeta || {},
    alias: indexVpGpbjLkYfMeta?.alias || [],
    redirect: indexVpGpbjLkYfMeta?.redirect,
    component: () => import("/node-app/pages/debug/index.vue").then(m => m.default || m)
  },
  {
    name: _91categoryId_93z7TI3GQvd2Meta?.name ?? "feed-categories-categoryId",
    path: _91categoryId_93z7TI3GQvd2Meta?.path ?? "/feed/categories/:categoryId()",
    meta: _91categoryId_93z7TI3GQvd2Meta || {},
    alias: _91categoryId_93z7TI3GQvd2Meta?.alias || [],
    redirect: _91categoryId_93z7TI3GQvd2Meta?.redirect,
    component: () => import("/node-app/pages/feed/categories/[categoryId].vue").then(m => m.default || m)
  },
  {
    name: indexMlv5xagFasMeta?.name ?? "feed",
    path: indexMlv5xagFasMeta?.path ?? "/feed",
    meta: indexMlv5xagFasMeta || {},
    alias: indexMlv5xagFasMeta?.alias || [],
    redirect: indexMlv5xagFasMeta?.redirect,
    component: () => import("/node-app/pages/feed/index.vue").then(m => m.default || m)
  },
  {
    name: indexAXIJC9SXblMeta?.name ?? "index",
    path: indexAXIJC9SXblMeta?.path ?? "/",
    meta: indexAXIJC9SXblMeta || {},
    alias: indexAXIJC9SXblMeta?.alias || [],
    redirect: indexAXIJC9SXblMeta?.redirect,
    component: () => import("/node-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxrHRBZgZ4PMeta?.name ?? "login",
    path: indexxrHRBZgZ4PMeta?.path ?? "/login",
    meta: indexxrHRBZgZ4PMeta || {},
    alias: indexxrHRBZgZ4PMeta?.alias || [],
    redirect: indexxrHRBZgZ4PMeta?.redirect,
    component: () => import("/node-app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: refreshWFn7h9l71mMeta?.name ?? "login-refresh",
    path: refreshWFn7h9l71mMeta?.path ?? "/login/refresh",
    meta: refreshWFn7h9l71mMeta || {},
    alias: refreshWFn7h9l71mMeta?.alias || [],
    redirect: refreshWFn7h9l71mMeta?.redirect,
    component: () => import("/node-app/pages/login/refresh.vue").then(m => m.default || m)
  },
  {
    name: indexZXtfF7TDLjMeta?.name ?? "more-ai-authors",
    path: indexZXtfF7TDLjMeta?.path ?? "/more/ai-authors",
    meta: indexZXtfF7TDLjMeta || {},
    alias: indexZXtfF7TDLjMeta?.alias || [],
    redirect: indexZXtfF7TDLjMeta?.redirect,
    component: () => import("/node-app/pages/more/ai-authors/index.vue").then(m => m.default || m)
  },
  {
    name: indexwYed8Pog2PMeta?.name ?? "more-ai-topics",
    path: indexwYed8Pog2PMeta?.path ?? "/more/ai-topics",
    meta: indexwYed8Pog2PMeta || {},
    alias: indexwYed8Pog2PMeta?.alias || [],
    redirect: indexwYed8Pog2PMeta?.redirect,
    component: () => import("/node-app/pages/more/ai-topics/index.vue").then(m => m.default || m)
  },
  {
    name: indexMdWTfg0af3Meta?.name ?? "more-authors",
    path: indexMdWTfg0af3Meta?.path ?? "/more/authors",
    meta: indexMdWTfg0af3Meta || {},
    alias: indexMdWTfg0af3Meta?.alias || [],
    redirect: indexMdWTfg0af3Meta?.redirect,
    component: () => import("/node-app/pages/more/authors/index.vue").then(m => m.default || m)
  },
  {
    name: indexVKnKQ6nCXyMeta?.name ?? "more-categories",
    path: indexVKnKQ6nCXyMeta?.path ?? "/more/categories",
    meta: indexVKnKQ6nCXyMeta || {},
    alias: indexVKnKQ6nCXyMeta?.alias || [],
    redirect: indexVKnKQ6nCXyMeta?.redirect,
    component: () => import("/node-app/pages/more/categories/index.vue").then(m => m.default || m)
  },
  {
    name: indexuaUnhxD5cVMeta?.name ?? "more",
    path: indexuaUnhxD5cVMeta?.path ?? "/more",
    meta: indexuaUnhxD5cVMeta || {},
    alias: indexuaUnhxD5cVMeta?.alias || [],
    redirect: indexuaUnhxD5cVMeta?.redirect,
    component: () => import("/node-app/pages/more/index.vue").then(m => m.default || m)
  },
  {
    name: indexnL9QlTXQ1OMeta?.name ?? "more-magazines",
    path: indexnL9QlTXQ1OMeta?.path ?? "/more/magazines",
    meta: indexnL9QlTXQ1OMeta || {},
    alias: indexnL9QlTXQ1OMeta?.alias || [],
    redirect: indexnL9QlTXQ1OMeta?.redirect,
    component: () => import("/node-app/pages/more/magazines/index.vue").then(m => m.default || m)
  },
  {
    name: indexX7IrHygKUQMeta?.name ?? "profile",
    path: indexX7IrHygKUQMeta?.path ?? "/profile",
    meta: indexX7IrHygKUQMeta || {},
    alias: indexX7IrHygKUQMeta?.alias || [],
    redirect: indexX7IrHygKUQMeta?.redirect,
    component: () => import("/node-app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91categoryId_934lOBwgISPQMeta?.name ?? "reader-categories-categoryId",
    path: _91categoryId_934lOBwgISPQMeta?.path ?? "/reader/categories/:categoryId()",
    meta: _91categoryId_934lOBwgISPQMeta || {},
    alias: _91categoryId_934lOBwgISPQMeta?.alias || [],
    redirect: _91categoryId_934lOBwgISPQMeta?.redirect,
    component: () => import("/node-app/pages/reader/categories/[categoryId].vue").then(m => m.default || m)
  },
  {
    name: indexoUEsPoJ6ymMeta?.name ?? "reader",
    path: indexoUEsPoJ6ymMeta?.path ?? "/reader",
    meta: indexoUEsPoJ6ymMeta || {},
    alias: indexoUEsPoJ6ymMeta?.alias || [],
    redirect: indexoUEsPoJ6ymMeta?.redirect,
    component: () => import("/node-app/pages/reader/index.vue").then(m => m.default || m)
  },
  {
    name: index98i5KceDvIMeta?.name ?? "trending",
    path: index98i5KceDvIMeta?.path ?? "/trending",
    meta: index98i5KceDvIMeta || {},
    alias: index98i5KceDvIMeta?.alias || [],
    redirect: index98i5KceDvIMeta?.redirect,
    component: () => import("/node-app/pages/trending/index.vue").then(m => m.default || m)
  }
]