<template>
  <NuxtLayout>
    <div>
      <div
        class="mt-20 sm:mt-0 h-[40vh] sm:h-[80vh] relative z-20 mx-auto max-w-screen-xl px-5 sm:px-8 pb-20 flex justify-center items-center min-h-[400px] sm:min-h-[300px]"
        :class="{'sm:h-[40vh] ': getErrorCode() === 404 }"
      >
        <div class="fit-text h-full absolute inset-x-0">
          {{ getErrorCode() }}
        </div>
        <div class="flex flex-col items-center gap-4 sticky bottom-12">
          <LogoMyFeed logo-black :link="routes.feed.path" />
          <span class="font-bold text-black text-center" v-html="getHeadingMessage()" />
          <span class="text-sm text-black-100 text-center max-w-48" v-html="getSubheadingMessage()" />
          <button
            class="mx-auto flex items-center gap-2 px-3 py-2 sm:px-4 sm:py-3 rounded-full bg-black font-bold text-white text-center p-1"
            @click="handleError"
          >
            {{ $t('errors.go_to_feed') }}
          </button>
        </div>
      </div>

      <div
        v-if="getErrorCode() === 404 && !shortArticleStore.isLoading && shortArticleStore.articles?.length > 0"
        class="relative z-20 mx-auto max-w-screen-xl px-5 sm:px-8 pb-20 -mt-10 sm:-mt-[20vh]"
      >
          <ArticleListing
            v-if="shortArticleStore.articles"
            :type="CategoryListingType.CAROUSEL"
            :articles="shortArticleStore.articles"
            :prev-page="RouteNames.FEED"
          />
      </div>

      <div
        v-if="getErrorCode() === 404 && shortArticleStore.isLoading"
        class="relative z-20 mx-auto max-w-screen-xl px-5 sm:px-8 pb-20 -mt-10 md:-mt-0"
      >
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 justify-center ">
          <SkeletonsArticleCard class="" />
          <SkeletonsArticleCard class="hidden md:block" />
          <SkeletonsArticleCard class="hidden sm:block" />
        </div>
      </div>

      <Footer/>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type {NuxtError} from '#app'
import { useI18n } from "vue-i18n"
import routes, {RouteNames} from "~/libs/configs/routes"
import CategoryListingType from "~/libs/interfaces/CategoryListingType";

const {t} = useI18n()
const logger = useFrontendLogger()
const shortArticleStore = useShortArticlesStore()

const props = defineProps({
  error: Object as () => NuxtError
})

const getErrorCode = () => {
  return props.error?.statusCode || 500
}

const getHeaderTitle = () => {
  switch (props.error?.statusCode) {
    case 404:
      return t('errors.error_404_header_title')
    default:
      if (props.error?.statusCode) {
        return `${t('errors.error')} ${props.error?.statusCode}`
      }
      return t('errors.error_500_heading')
  }

}

const getHeadingMessage = () => {
  switch (props.error?.statusCode) {
    case 404:
      return t('errors.error_404_heading')
    default:
      return t('errors.error_500_heading')
  }
}

const getSubheadingMessage = () => {
  switch (props.error?.statusCode) {
    case 404:
      return t('errors.error_404_subheading')
    default:
      return t('errors.error_500_subheading')
  }
}

const handleError = () => clearError({redirect: routes.feed.path})

// load articles for 404
if (getErrorCode() === 404) {
  useAsyncData('articles', () => shortArticleStore.fetchArticles())
}

// log error
logger.error('Unhandled error - shown error page', props.error)
</script>

<style lang="postcss">

  .fit-text {
    @apply text-[50vw] lg:text-[560px] font-bold text-black text-center;
    white-space: nowrap;
    opacity: 2%;
    line-height: 1;
  }

</style>
