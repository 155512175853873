import {defineNuxtPlugin} from '#app'
import {HttpLink, from} from '@apollo/client/core'
import {setContext} from '@apollo/client/link/context'

export default defineNuxtPlugin(nuxtAppMain => {
  const runtimeConfig = useRuntimeConfig()

  const nuxtApp = useNuxtApp();

  const httpLink = new HttpLink({
    uri: process.client ? '/graphql' : process.env.LOCAL_SERVER_GRAPHQL_API_URL || 'http://localhost:3000/graphql',
  })

  const userUuid = useCookie(runtimeConfig.public.uuidCookieName)

  const authLink = setContext((_, {headers}) => {
    return {
      headers: {
        ...headers,
        ...(userUuid.value && {
          'x-user-uuid': userUuid.value
        }),
      },
    }
  })

  nuxtApp.$apollo.defaultClient.setLink(from([authLink.concat(httpLink)]));

  nuxtApp.hook('apollo:error', (error) => {
    console.error('apollo:error', error);
  });
})
