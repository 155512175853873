export default defineAppConfig({
  ui: {
    primary: 'white',
    gray: 'neutral',
    notification: {
      background: 'bg-black',
      title: 'text-sm font-medium text-white dark:text-white',
      description: 'mt-1 text-sm leading-4 dark:text-white text-white',
      shadow: 'shadow-lg',
      rounded: 'rounded-3xl',
      padding: 'p-3',
      ring: 'ring-1 ring-gray-600',
      icon: {
        base: 'flex-shrink-0 w-7 h-7',
        color: 'text-white dark:text-white',
      },
      default: {
        closeButton: { icon: 'i-heroicons-x-mark', color: 'white', variant: 'solid', padded: true, size: '2xs', ui: { rounded: 'rounded-full' } }
      }
    },
    notifications: {
      // Show toasts at the top right of the screen
      position: 'top-0 bottom-auto  sm:ml-0 sm:left-[5rem] sm:top-auto sm:bottom-14',
      container: 'px-2 sm:px-6 py-4 space-y-3 overflow-y-auto',
    }
  }
})
