import type ArticleLocal from "~/libs/interfaces/ArticleLocal"
export const useArticleCategoryIdSearch = (
  useAiArticles: boolean,
  article?: ArticleLocal,
  ids?: string[],
): boolean => {
  if (!ids || ids.length === 0 || !article) {
    return true
  }

  // filter selected media
  let found = false
  if (!useAiArticles || !article.aiTopicList?.edges?.length) {
    article.categoryList?.edges?.forEach((category) => {
      if (category?.node?.id && ids.includes(category?.node?.id)) {
        found = true
      }
    })
  } else {
    article.aiTopicList?.edges?.forEach((topic) => {
      if (topic?.node?.id && ids.includes(topic?.node?.id)) {
        found = true
      }
    })
  }
  return found
}
