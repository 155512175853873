<template>
  <svg viewBox="0 0 110 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M98.8052 27.0865H89.8125V26.7254L91.9433 23.7639V3.32261L89.8125 0.361155V0H98.8052C103.934 0 109.965 3.25038 109.965 13.5433C109.965 23.8361 103.934 27.0865 98.8052 27.0865ZM98.8052 26.3642H99.1664C101.514 26.3642 102.561 20.6941 102.561 13.3627C102.561 6.21184 101.514 0.722308 99.1664 0.722308H98.8052V26.3642Z"
      fill="white"/>
    <path
      d="M88.366 27.0865H71.8613V26.7254L73.9921 23.7639V3.32261L71.8613 0.361155V0H88.366V6.8258H88.0049L81.5764 0.722308H80.854V12.6404L86.9214 9.78726H87.2826V16.2158H86.9214L80.854 13.3627V26.3642H81.5764L88.0049 20.2607H88.366V27.0865Z"
      fill="white"/>
    <path
      d="M70.4129 27.0865H53.9082V26.7254L56.039 23.7639V3.32261L53.9082 0.361155V0H70.4129V6.8258H70.0518L63.6232 0.722308H62.9009V12.6404L68.9683 9.78726H69.3295V16.2158H68.9683L62.9009 13.3627V26.3642H63.6232L70.0518 20.2607H70.4129V27.0865Z"
      fill="white"/>
    <path
      d="M47.7952 27.0865H36.3105V26.7254L38.4414 23.7639V3.32261L36.3105 0.361155V0H52.8153V7.54811H52.4541L46.0256 0.722308H45.3033V13.3627L51.3706 10.5096H51.7318V16.9381H51.3706L45.3033 14.085V23.7639L47.7952 26.7254V27.0865Z"
      fill="white"/>
    <path
      d="M26.4448 28.2058L25.1447 11.3761L23.3389 10.0398L23.4112 9.8231L27.4922 7.51172H27.7089L29.1535 24.9915C31.7899 21.3078 35.6543 15.0959 35.0764 10.3648C34.6791 11.051 33.9568 11.4122 33.2345 11.4122C32.2233 11.4122 31.6093 10.726 31.6093 9.8231C31.6093 8.37849 32.8373 7.51172 33.8485 7.51172C35.582 7.51172 35.8348 9.71476 35.8348 10.9788C35.7626 19.1047 24.7474 33.3703 19.5107 33.3703C18.03 33.3703 17.416 32.5758 17.416 31.6007C17.416 30.2283 18.6801 29.3976 19.6913 29.3976C20.6664 29.3976 21.2442 30.0838 21.2442 30.9145C21.2442 31.6729 20.8831 32.2146 20.5581 32.5396C22.2194 32.034 24.3863 30.3727 26.4448 28.2058Z"
      fill="white"/>
    <path
      d="M20.4066 27.0865H14.7004L14.7365 26.8698L16.3978 24.8474L18.6731 2.96146H18.6008L10.6194 27.0865H9.89705L9.39144 3.17815H9.28309L4.1186 23.4389L5.20206 26.8698L5.12983 27.0865H0.976562L1.01268 26.8698L3.61298 23.4389L8.95805 2.60031L7.91071 0.216694L7.94682 0H12.1723L12.4612 20.1524H12.5335L19.1065 0H23.4403L23.4042 0.216694L21.5623 2.23915L19.2148 24.8474L20.4427 26.8698L20.4066 27.0865Z"
      fill="white"/>
  </svg>
</template>