<template>
  <div
    class="absolute top-2 right-2.5 px-2 py-1 flex gap-0.5 h-6 items-center bg-black/35 rounded-full backdrop-blur-sm">
    <div class="w-px h-2/5 bg-white rounded-full"></div>
    <div class="w-px h-3/4 bg-white rounded-full"></div>
    <div class="w-px h-4/5 bg-white rounded-full"></div>
    <div class="w-px h-full bg-white rounded-full"></div>
    <div class="w-px h-4/5 bg-white rounded-full"></div>
    <div class="w-px h-2/5 bg-white rounded-full"></div>
    <div class="w-px h-full bg-white rounded-full"></div>
    <div class="w-px h-3/5 bg-white rounded-full"></div>
    <div class="w-px h-1/5 bg-white rounded-full"></div>
    <div class="w-px h-2/5 bg-white rounded-full"></div>
  </div>
</template>
