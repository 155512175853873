import {useMyArticlesStore, useMyAuthorsStore, useMyCategoriesStore, useMyMediaStore} from "~/stores/useMyApiStore";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const oidc = useOidc()
  if (oidc.isLoggedIn) {
    // load user settings
    const myArticlesStore = useMyArticlesStore()
    const myAuthorsStore = useMyAuthorsStore()
    const myCategoriesStore = useMyCategoriesStore()
    const myMediaStore = useMyMediaStore()

    await myArticlesStore.fetchIds()
    await myAuthorsStore.fetchIds()
    await myCategoriesStore.fetchIds()
    await myMediaStore.fetchIds()
  }
})
