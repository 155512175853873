import validate from "/node-app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45uuid_45global from "/node-app/middleware/01.uuid.global.ts";
import _02_45oidc_45global from "/node-app/middleware/02.oidc.global.ts";
import _03_45user_45preferences_45global from "/node-app/middleware/03.userPreferences.global.ts";
import _04_45sentry_45global from "/node-app/middleware/04.sentry.global.ts";
import manifest_45route_45rule from "/node-app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45uuid_45global,
  _02_45oidc_45global,
  _03_45user_45preferences_45global,
  _04_45sentry_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}