import {GET_ARTICLES} from "~/graphql/articles"
import type {Article, ArticleEdge} from "~/graphql/__generated__/graphql"
import {useDate} from "~/composables/useDate"
import type ArticleLocal from "~/libs/interfaces/ArticleLocal"
import articleLocalFactory from "~/libs/factories/ArticleLocalFactory"
import {PersistedStoreType, usePersistedStore} from "~/stores/usePersistedStore"
import {useArticleMagazineIdSearch} from "~/composables/useArticleMagazineIdSearch"
import {useArticleAuthorIdSearch} from "~/composables/useArticleAuthorIdSearch"
import {useArticleCategoryIdSearch} from "~/composables/useArticleCategoryIdSearch"
import {useApiIdConverter} from "~/composables/useApiIdConverter"

const logger = useFrontendLogger()

export const useShortArticlesStore = defineStore('shortArticles', {
  state: () => {
    const state: {
      isLoading: Ref<boolean>
      articles: ArticleLocal[]
    } = {
      isLoading: ref(false),
      articles: reactive([]),
    }
    return state
  },
  getters: {
    getIsLoading(state): boolean {
      return state.isLoading
    },
  },
  actions: {
    /**
     * Get articles with applied filters and presets
     * @param offset
     * @param limit
     */
    getFilteredArticles(offset: number = 0, limit: number = 10): ArticleLocal[] {
      const { public: {
        enableMagazineArticleFilter,
        enableCategoryArticleFilter,
        enableAuthorArticleFilter,
        useAiArticles
      }} = useRuntimeConfig()

      const myMediaStore = useMyMediaStore()
      const myCategoriesStore = useMyCategoriesStore()
      const myAuthorsStore = useMyAuthorsStore()

      let visibleCount = 0
      let trendingCount = 0
      return this.articles.map((article) => {
        let isVisible = true

        // User preference - filter selected media
        if (enableMagazineArticleFilter && isVisible) {
          isVisible = useArticleMagazineIdSearch(article, myMediaStore.storedIds)
        }

        // User preference - filter selected category
        if (enableCategoryArticleFilter && isVisible) {
          isVisible = useArticleCategoryIdSearch(useAiArticles, article, myCategoriesStore.storedIds)
        }

        // User preference - filter selected author
        if (enableAuthorArticleFilter && isVisible) {
          isVisible = useArticleAuthorIdSearch(useAiArticles, article, myAuthorsStore.storedIds)
        }

        if (isVisible && visibleCount < offset) {
          isVisible = false
          visibleCount = visibleCount + 1
        }

        if (isVisible) {
          trendingCount = trendingCount + 1
        }

        if (limit < trendingCount) {
          isVisible = false
        }

        return {
          ...article,
          isVisible,
          trendingOrder: trendingCount
        }
      })
    },
    /**
     * Get count of getFilteredArticles
     * @param offset
     */
    getFilteredArticlesCount(offset: number = 0): number {
      const articles = this.getFilteredArticles(offset).filter(article => article.isVisible)
      return articles.length
    },
    /**
     * Get article by articleId
     * @param id
     */
    getArticle(id: string): ArticleLocal | undefined {
      return this.articles.find((article) => {
        return article.id.indexOf(id) !== -1
      })
    },
    /**
     * Fetch articles from server
     * @param limit
     */
    async fetchArticles(limit: number = 6): Promise<void> {
      const { public: {
        enableMagazineArticleFilter,
        enableCategoryArticleFilter,
        enableAuthorArticleFilter,
        showOnlyPremiumContent,
        useAiArticles
      }} = useRuntimeConfig()

      const persistedStore = usePersistedStore()
      if (this.articles.length > 1 && !persistedStore.needSync(PersistedStoreType.SHORT_ARTICLES)) {
        return
      }

      const myMediaStore = useMyMediaStore()
      const myCategoriesStore = useMyCategoriesStore()
      const myAuthorsStore = useMyAuthorsStore()

      const apiIdToUuid = useApiIdConverter().apiIdToUuid

      this.isLoading = true
      const startOfTheDay = useDate().todayString()
      const filter = {
        first: limit,
        isDeleted: false,
        isCompleted: true,
        isPreview: false,
        ...(showOnlyPremiumContent && {
          premium: true
        }),
        published: [{
          after: startOfTheDay,
          before: new Date().toISOString()
        }],
        // custom filters
        ...(enableMagazineArticleFilter && myMediaStore.storedIds && myMediaStore.storedIds.length > 0 && {
          magazineList: myMediaStore.storedIds.map(apiIdToUuid)
        }),
        ...(!useAiArticles && enableCategoryArticleFilter && myCategoriesStore.storedIds && myCategoriesStore.storedIds.length > 0 && {
          categoryListList: myCategoriesStore.storedIds.map(apiIdToUuid)
        }),
        ...(!useAiArticles && enableAuthorArticleFilter && myAuthorsStore.storedIds && myAuthorsStore.storedIds.length > 0 && {
          authorListList: myAuthorsStore.storedIds.map(apiIdToUuid)
        }),
        // AI filters
        ...(useAiArticles && enableCategoryArticleFilter && myCategoriesStore.storedIds && myCategoriesStore.storedIds.length > 0 && {
          aiTopicListList: myCategoriesStore.storedIds.map(apiIdToUuid)
        }),
        ...(useAiArticles && enableAuthorArticleFilter && myAuthorsStore.storedIds && myAuthorsStore.storedIds.length > 0 && {
          aiAuthorListList: myAuthorsStore.storedIds.map(apiIdToUuid)
        }),
        ...(useAiArticles && {
          hasAiValues: true
        }),
      }

      if (import.meta.server) {
        try {
          const result = await useAsyncQuery(GET_ARTICLES, filter)
          if (result.error?.value) {
            logger?.error('useShortArticlesStore:fetchArticles server error', {errorDetail: result.error?.value})
          }

          //@ts-ignore
          const edges = result.data?.value?.articles?.edges || []
          const newArticles = edges.map((item: ArticleEdge) => item.node)
          this.addArticles(newArticles)
        } catch (e) {
          logger?.error('useShortArticlesStore:fetchArticles server exception', {errorDetail: e})
        }
        this.isLoading = false
      } else {
        try {
          const {onResult, onError} = useQuery(GET_ARTICLES, filter)
          onResult((result) => {
            this.isLoading = result.loading
            if (!result.loading) {
              const data = result.data?.articles?.edges || []
              if (data.length) {
                const newArticles = data.map((item: ArticleEdge) => item.node)
                this.addArticles(newArticles)
              }
            }
          })
          onError((error) => {
            this.isLoading = false
            logger?.error('useShortArticlesStore:fetchArticles client error', {errorDetail: error})
          })
        } catch (e) {
          this.isLoading = false
          logger?.error('useShortArticlesStore:fetchArticles client exception', {errorDetail: e})
        }
      }

      persistedStore.setLastSync(PersistedStoreType.SHORT_ARTICLES)
    },
    /**
     * Add articles to store - remove duplicities
     * @param newArticles
     * @param rewrite
     */
    addArticles(newArticles: Article[], rewrite: boolean = false): void {
      const { public: {
        useAiArticles
      }} = useRuntimeConfig()
      newArticles.forEach((newArticle) => {
        const articleLocal = articleLocalFactory.createArticleLocal(newArticle, useAiArticles)
        const foundIndex = this.articles.findIndex(article => article.id === newArticle.id)
        if (foundIndex >= 0 && rewrite) {
          this.articles.splice(foundIndex, 1)
        }
        if (foundIndex === -1 || rewrite) {
          this.articles.push(articleLocal)
        }
      })

      // sort articles
      this.articles.sort((a, b) => {
        if (a.publishedDate && b.publishedDate) {
          return a.publishedDate?.getTime() > b?.publishedDate?.getTime() ? -1 : 1
        }
        return 1
      })
    }
  }
})
