import revive_payload_client_4sVQNw7RlN from "/node-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/node-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/node-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/node-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/node-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/node-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/node-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/node-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/node-app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_t2GMTTFnMT from "/node-app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_gu48BD4aNq from "/node-app/node_modules/@webowski/nuxt-openid-connect/dist/runtime/plugin.js";
import slideovers_LDumGYo2KH from "/node-app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/node-app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/node-app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/node-app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_eTVJQYlCmx from "/node-app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/node-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/node-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_AUP22rrBAc from "/node-app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_1ILFkzpYSr from "/node-app/plugins/apollo.ts";
import dragscroll_U3dCpB8oXl from "/node-app/plugins/dragscroll.ts";
import node_client_1ZbTG6016B from "/node-app/plugins/node.client.ts";
import sentry_3AyO8nEfhE from "/node-app/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_t2GMTTFnMT,
  plugin_gu48BD4aNq,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  plugin_eTVJQYlCmx,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  plugin_AUP22rrBAc,
  apollo_1ILFkzpYSr,
  dragscroll_U3dCpB8oXl,
  node_client_1ZbTG6016B,
  sentry_3AyO8nEfhE
]