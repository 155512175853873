export interface RouteItem {
  name: string
  path: string
  translationKey: string
}

export enum RouteNames {
  FEED = "feed",
  READER = "reader",
  TRENDING = "trending",
  MORE = "more",
  MORE_AUTHORS = "more_authors",
  MORE_CATEGORIES = "more_categories",
  MORE_AI_AUTHORS = "more_ai_authors",
  MORE_AI_TOPICS = "more_ai_topics",
  MORE_MAGAZINES = "more_magazines",
  FEED_CATEGORIES = "feed_categories",
  READER_CATEGORIES = "reader_categories",
  ARTICLES = "articles",
  PROFILE = "profile",
}

const routes: Record<RouteNames, RouteItem> = {
  [RouteNames.FEED]: {
    name: RouteNames.FEED,
    path: "/feed",
    translationKey: "menu.feed"
  },
  [RouteNames.READER]: {
    name: RouteNames.READER,
    path: "/reader",
    translationKey: "menu.reader"
  },
  [RouteNames.TRENDING]: {
    name: RouteNames.TRENDING,
    path: "/trending",
    translationKey: "menu.trending"
  },
  [RouteNames.MORE]: {
    name: RouteNames.MORE,
    path: "/more",
    translationKey: "menu.more"
  },
  [RouteNames.MORE_AUTHORS]: {
    name: RouteNames.MORE_AUTHORS,
    path: "/more/authors",
    translationKey: ""
  },
  [RouteNames.MORE_CATEGORIES]: {
    name: RouteNames.MORE_CATEGORIES,
    path: "/more/categories",
    translationKey: ""
  },
  [RouteNames.MORE_AI_AUTHORS]: {
    name: RouteNames.MORE_AI_AUTHORS,
    path: "/more/ai-authors",
    translationKey: ""
  },
  [RouteNames.MORE_AI_TOPICS]: {
    name: RouteNames.MORE_AI_TOPICS,
    path: "/more/ai-topics",
    translationKey: ""
  },
  [RouteNames.MORE_MAGAZINES]: {
    name: RouteNames.MORE_MAGAZINES,
    path: "/more/magazines",
    translationKey: ""
  },
  [RouteNames.FEED_CATEGORIES]: {
    name: RouteNames.FEED_CATEGORIES,
    path: "/feed/categories",
    translationKey: "menu.categories"
  },
  [RouteNames.READER_CATEGORIES]: {
    name: RouteNames.READER_CATEGORIES,
    path: "/reader/categories",
    translationKey: "menu.categories"
  },
  [RouteNames.ARTICLES]: {
    name: RouteNames.ARTICLES,
    path: "/articles",
    translationKey: "menu.articles"
  },
  [RouteNames.PROFILE]: {
    name: RouteNames.PROFILE,
    path: "/profile",
    translationKey: "menu.profile"
  }

}
export default routes
