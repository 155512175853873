<template>
  <label
    v-bind="getTooltip"
    @mouseover="showAnimation = false"
    @click="hideTooltip"
    class="add-button relative w-6 h-6 lg:w-8 lg:h-8 rounded-full bg-black"
    :class="{'add-button--new_user': newUser && showAnimation}"
    for="checkbox"
  >
    <input
      :value="id"
      @change="toggleToast"
      v-model="myArticlesStore.storedIds"
      class="absolute inset-0 appearance-none w-full h-full cursor-pointer"
      type="checkbox"
    >
    <svg class="add-button__icon add-button__icon--add relative fill-white" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path d="M697.4 759.2l61.8-61.8L573.8 512l185.4-185.4-61.8-61.8L512 450.2 326.6 264.8l-61.8 61.8L450.2 512 264.8 697.4l61.8 61.8L512 573.8z"></path>
    </svg>
    <svg class="add-button__icon add-button__icon--added p-0.5 lg:p-[3px] lg:pt-1" width="100%" height="100%" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.08132 13.2269L18.6413 2.66688L16.764 0.789551L8.10266 9.45089L2.42799 3.79755L0.550659 5.69622L8.08132 13.2269Z"/>
    </svg>
  </label>
</template>

<script setup lang="ts">

import {useCustomToast} from "~/composables/useCustomToast";

const { isDesktop } = useDevice();
const {t} = useI18n()
import {myStoreSyncSubscribeRegister, useMyArticlesStore} from "~/stores/useMyApiStore";
const myArticlesStore = useMyArticlesStore()

myStoreSyncSubscribeRegister(myArticlesStore)


const toast = useCustomToast()

const toggleToast = () => {
  if (myArticlesStore.storedIds.includes(id)) {
    toast.add({
      title: t('article.toast_added_to_reader'),
    })
  } else {
    toast.remove({
      title: t('article.toast_removed_from_reader'),
    })
  }

}

const {
  id,
  isReader,
  isNewUser,
}  = defineProps({
  id: {type: String, required: true},
  isNewUser: {type: Boolean, required: false, default: false},
  isReader: {type: Boolean, required: false, default: false},
})

const newUser = ref(false)
const showAnimation = ref(false);

const hideTooltip = () => {
  if (!isDesktop) {
    showAnimation.value = false
  }
}

onMounted(() => {
  if (import.meta.client) {
    newUser.value = isNewUser
    showAnimation.value = newUser.value
  }
})

const getTooltip = computed(() => {
  return newUser.value && showAnimation.value || isDesktop ?
    {
      'data-tooltip': myArticlesStore.storedIds?.includes(id) ? tooltipRemove : tooltipAdd
    }
    : {}
})

const tooltipAdd = isReader ? t('article.reader_button_reader_tooltip_add') : t('article.reader_button_tooltip_add');
const tooltipRemove = isReader ? t('article.reader_button_reader_tooltip_remove') : t('article.reader_button_tooltip_remove');

</script>

<style lang="postcss" scoped>

.add-button {
  @apply border-2 lg:border-[3px] border-black ;
  transition: 250ms all cubic-bezier(0.33, 1, 0.68, 1);
}

.add-button input {
  z-index: 5;
}

.add-button__icon {
  transition: 250ms all cubic-bezier(0.33, 1, 0.68, 1);
}

.add-button__icon--added {
  @apply scale-0 rotate-[360deg];
}

.add-button__icon--add {
  @apply scale-100 absolute rotate-[315deg];
}

.add-button:has(> input:checked) {
  @apply bg-white;
  -webkit-animation: adding 250ms cubic-bezier(0.33, 1, 0.68, 1) both;
  animation: adding 250ms cubic-bezier(0.33, 1, 0.68, 1) both;
}

.add-button input:checked ~ .add-button__icon--add {
  @apply opacity-0 absolute scale-0;
}

.add-button input:checked ~ .add-button__icon--added {
  @apply block fill-black scale-100 rotate-0;
}

</style>

<style lang="postcss">

  .add-button--new_user[data-tooltip]::after,
  .add-button--new_user[data-tooltip]::before {
    display: block;
    opacity: 0;
    -webkit-animation: tooltip-reveal-device 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: tooltip-reveal-device 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-iteration-count: 4;
    box-shadow: 0 3px 16px 0 #00000059;
    z-index: 30;
    transform: translate(-50%, 0);
  }

  .add-button--new_user[data-tooltip]::after {
    transform: translate(-50%, 0) rotate(45deg);
  }

</style>
