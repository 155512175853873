import { MyApiEndpoint } from "~/libs/configs/apiEndpoints"
import difference from "lodash.difference"
import type {StoreDefinition} from "pinia"
import {usePersistedStore} from "~/stores/usePersistedStore"

const logger = useFrontendLogger()
const getEndpoint = (endpoint:MyApiEndpoint): string => {
  return `/api/my/${endpoint}`
}

export const myStoreSyncSubscribeRegister = (store) => {
  if (process.server) {
    return
  }

  if (!store.$subscribe) {
    return
  }

  if (store.subscribeRegistered) {
    return
  }

  store.subscribeRegistered = true
    store.$subscribe(
    (mutation, state) => { myStoreSyncSubscribe(store, mutation, state) },
    { detached: true }
  )
}

export const myStoreSyncSubscribe = (store, mutation, state) => {
  console.log('change')
  console.log(state.storedIds)
  console.log(state.remoteStoredIds)
  const added = difference(state.storedIds, state.remoteStoredIds)
  const removed = difference(state.remoteStoredIds, state.storedIds)

  if (added.length > 0) {
    console.log('add', added)
    store.addItem(added[0])
  }
  if (removed.length > 0) {
    console.log('remove', removed)
    store.deleteItem(removed[0])
  }
}

const myStoreFactory = (endpoint: MyApiEndpoint): StoreDefinition => {
  return defineStore(`my_${endpoint}`, {
    state: () => {
      const state: {
        subscribeRegistered: Ref<boolean>
        isLoading: Ref<boolean>,
        isFetched: Ref<boolean>,
        storedIds: string[]
        remoteStoredIds: string[]
      } = {
        subscribeRegistered: ref(false),
        isLoading: ref(false),
        isFetched: ref(false),
        storedIds: reactive([]),
        remoteStoredIds: reactive([]),
      }
      return state
    },
    getters: {
      getIsLoading(state): boolean {
        return state.isLoading
      },
      isStored: (state) => {
        return (id: string): Boolean => {
          return state.storedIds?.includes(id)
        }
      },
      hasStoredIds: (state) => {
        return state.storedIds.length > 0
      }
    },
    actions: {
      async addItem(id: string): Promise<void> {
        console.log('ADD',id)
        try {
          await $fetch(getEndpoint(endpoint), {
            method: "POST",
            body: {"id": id}
          })
          this.remoteStoredIds = [...this.storedIds]
          // reset need sync flag
          const persistedStore = usePersistedStore()
          persistedStore.resetLastSyncAll()
        } catch (e) {
          const index = this.storedIds.indexOf(id)
          this.storedIds.splice(index, 1)
          logger.error(`MyApiStore:${endpoint}:addItem - exception`, {errorDetail: e})
        }
      },

      async deleteItem(id: string): Promise<void> {
        console.log('DELETE',id)
        try {
          await $fetch(getEndpoint(endpoint), {
            method: "DELETE",
            body: {"id": id}
          })
          this.remoteStoredIds = [...this.storedIds]
          // reset need sync flag
          const persistedStore = usePersistedStore()
          persistedStore.resetLastSyncAll()
        } catch (e) {
          this.storedIds.push(id)
          logger.error(`MyApiStore:${endpoint}:deleteItem - exception`, {errorDetail: e})
        }
      },

      async fetchIds(force: Boolean = false): Promise<void> {
        console.log(`[MyApiStore::FETCH_INIT::${endpoint}]`)
        if (this.isFetched && force === false) {
          return
        }
        console.log(`[MyApiStore::FETCH::${endpoint}]`)
        this.isLoading = true
        try {
          const {data} = await useFetch(getEndpoint(endpoint))
          if (data.value && Array.isArray(data.value)) {
            const ids = data.value as string[]
            this.storedIds = ids || []
            this.remoteStoredIds = [...ids]
            this.isFetched = true
          }
        } catch (e) {
          logger.error(`MyApiStore:${endpoint}:fetchIds - exception`, {errorDetail: e})
        }
        this.isLoading = false
        console.log(`[MyApiStore::FETCH_END::${endpoint}]`)
      },

    }
  })
}

export const useMyArticlesStore = myStoreFactory(MyApiEndpoint.ARTICLES)
export const useMyAuthorsStore = myStoreFactory(MyApiEndpoint.AUTHORS)
export const useMyCategoriesStore = myStoreFactory(MyApiEndpoint.CATEGORIES)
export const useMyMediaStore = myStoreFactory(MyApiEndpoint.MEDIA)
