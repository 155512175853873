<template>
  <img
    v-if="src && !imageNotFound"
    loading="lazy"
    class="object-cover h-full w-full"
    :src="String(src)"
    :alt="alt || ''"
    @error="onError"
  />
  <img
    v-else-if="imageNotFound"
    class="object-cover h-full w-full"
    :src="screen1"
    :alt="alt || ''"
  />
  <img
    v-else="imageNotFound"
    class="object-cover h-full w-full"
    :src="screen1"
    :alt="alt || ''"
  />
</template>

<script setup lang="ts">
import type {Maybe} from "@graphql-tools/utils";

import screen1 from '~/assets/images/not-found/not-found-1.jpg';
import screen2 from '~/assets/images/not-found/not-found-2.jpg';

const imageNotFound = ref(false)

const onError = (e) => {
  imageNotFound.value = true
}

const {src, alt} = defineProps<{
  src?: Maybe<string>,
  alt?: Maybe<string>
}>()
</script>
