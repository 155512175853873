import gql from 'graphql-tag'

export const GET_ARTICLES = gql`
  query articles(
    $first: Int,
    $last: Int,
    $before: String,
    $after: String,
    $magazine: String,
    $magazineList: [String],
    $categoryList: String,
    $categoryListList: [String],
    $aiTopicList: String,
    $aiTopicListList: [String],
    $aiAuthorList: String,
    $aiAuthorListList: [String],
    $path: String,
    $path_list: [String],
    $id: String,
    $idList: [String],
    $originId: String,
    $originIdList: [String],
    $labelList: String,
    $labelListList: [String],
    $authorList: String,
    $authorListList: [String],
    $published: [ArticleFilter_published],
    $unpublished: [ArticleFilter_unpublished],
    $title: String,
    $perex: String,
    $body: String,
    $isDeleted: Boolean,
    $isCompleted: Boolean,
    $isPreview: Boolean,
    $premium: Boolean,
    $hasAiValues: Boolean
  ) {
    articles(
      first: $first
      last: $last
      before: $before
      after: $after
      magazine: $magazine
      magazine_list: $magazineList
      categoryList: $categoryList
      categoryList_list: $categoryListList
      path: $path
      path_list: $path_list
      id: $id,
      id_list: $idList,    
      originId: $originId
      originId_list: $originIdList
      labelList: $labelList
      labelList_list: $labelListList
      authorList: $authorList
      authorList_list: $authorListList
      published: $published
      unpublished: $unpublished
      title: $title
      perex: $perex
      body: $body
      isDeleted: $isDeleted
      isCompleted: $isCompleted
      isPreview: $isPreview
      premium: $premium
      aiTopicList: $aiTopicList
      aiTopicList_list: $aiTopicListList
      aiAuthorList: $aiAuthorList
      aiAuthorList_list: $aiAuthorListList
      hasAiValues: $hasAiValues
    ) {
      edges {
        node {
          id
          authorList {
            edges {
              node {
                id
                fullname,
                imageAsset {
                  path
                  urlWithProtocol
                }
              }
            }
          },
          title
          body
          previewAsset {
            path
            urlWithProtocol
            author
            source
          },
          published,
          magazine {
            id
            name
          }
          categoryList {
            edges {
              node {
                id
                name
              }
            }
          }
          articleLinkAssetList {
            edges {
              node {
                id,
                asset {
                  title,
                  caption,
                  source,
                  author,
                  path
                },
                position
              }
            }
          }
          aiAuthorList {
            edges {
              node {
                id
                name
                imageAsset {
                  urlWithProtocol
                }
              }
            }
          }
          aiTopicList {
            edges {
              node {
                id
                name
              }
            }
          }
          hasAiValues
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`