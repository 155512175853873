import type ArticleLocal from "~/libs/interfaces/ArticleLocal"
export const useArticleAuthorIdSearch = (
  useAiArticles: boolean,
  article?: ArticleLocal,
  ids?: string[],
): boolean => {
  if (!ids || ids.length === 0 || !article) {
    return true
  }

  // filter selected media
  let found = false
  if (!useAiArticles || !article.aiAuthorList?.edges?.length) {
    article.authorList?.edges?.forEach((author) => {
      if (author?.node?.id && ids.includes(author?.node?.id)) {
        found = true
      }
    })
  } else {
    article.aiAuthorList?.edges?.forEach((author) => {
      if (author?.node?.id && ids.includes(author?.node?.id)) {
        found = true
      }
    })
  }
  return found
}
