<template>
  <div
    v-if="author"
    :class="!isDetail ? 'bg-neutral-100' : 'bg-white'"
    class="flex gap-1 items-center py-1 px-2 pl-1 rounded-full max-w-fit"
  >
    <div
      :class="!isDetail ? 'w-6 h-6' : 'w-8 h-8'"
      class="w-6 h-6 rounded-full bg-white overflow-hidden"
    >
      <img
        loading="lazy"
        v-if="author.imageUrlWithProtocol && !imageError"
        class="object-cover h-full w-full"
        :src="author.imageUrlWithProtocol"
        :alt="author.name || ''"
        @error="replaceByDefault"
      >
      <!--no profile-pic -->
      <IconsDefaultAuthor v-if="!author.imageUrlWithProtocol || imageError" class="w-full h-full p-1.5 fill-none" />
      <!--no profile-pic -->
    </div>

    <span
      :class="!isDetail ? 'text-xs' : 'text-base'"
      class="text-black"
    >
      {{ author.name }}
    </span>

    <div
      :class="!isDetail ? 'w-2.5 h-2.5' : 'w-4 h-4'"
    >
      <!-- TODO - checkbox OR remove icon - reader saved authors -->
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 11" fill="none">
        <path
          d="M5.33398 9.40381C2.9082 9.40381 0.939453 7.43506 0.939453 5.00928C0.939453 2.5835 2.9082 0.614746 5.33398 0.614746C7.75977 0.614746 9.72852 2.5835 9.72852 5.00928C9.72852 7.43506 7.75977 9.40381 5.33398 9.40381ZM2.81445 4.58154L4.88281 6.6499L7.7832 3.74951L7.26758 3.23389L4.88867 5.61279L3.33008 4.06006L2.81445 4.58154Z"
          fill="black"/>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {Maybe} from "@graphql-tools/utils";
import type SimpleAuthor from "~/libs/interfaces/SimpleAuthor";

const {author, isDetail} = defineProps<{
  author?: Maybe<SimpleAuthor>,
  isDetail?: boolean,
}>()

const imageError = ref(false)

const replaceByDefault = () => {
  imageError.value = true
}

</script>

<style lang="postcss" scoped>
.badge {
  @apply inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700;

  &:hover {
    @apply bg-gray-300;
  }
}
</style>
