export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useRuntimeConfig()
  const loginPath = config.public.loginPath
  const refreshPath = "/login/refresh"
  const homepagePath = config.public.homepagePath
  const accessTokenCookieName = config.public.openidConnect.config.cookiePrefix + "access_token"
  const refreshTokenCookieName = config.public.openidConnect.config.cookiePrefix + "refresh_token"
  const loginCookie = useCookie(accessTokenCookieName)
  const refreshTokenCookie = useCookie(refreshTokenCookieName)
  const oidc = useOidc()

  const isLoggedInValid = () => {
    return oidc.isLoggedIn && oidc.user?.email
  }

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  if (to.fullPath === '/debug') {
    return
  }

  if (oidc.isLoading) {
    while(oidc.isLoading) {
      await sleep(50)
    }
  }

  // logged in but navigating to login page
  if (isLoggedInValid() && (to.fullPath === loginPath || to.fullPath === refreshPath)) {
    return navigateTo(homepagePath)
  }

  if (to.fullPath === refreshPath) {
    if (import.meta.server) {
      return
    }
    if (import.meta.client && !!refreshTokenCookie.value) {
      await oidc.fetchUser()
      if (oidc.isLoggedIn) {
        return navigateTo(homepagePath)
      } else {
        return navigateTo(loginPath)
      }
    }
    return navigateTo(loginPath)
  }

  // other page and logged in
  if (isLoggedInValid()) {
    return
  }

  // server
  if (import.meta.server) {
    // logged in but navigating to login page on SSR
    if (!!loginCookie.value && to.fullPath === loginPath) {
      return navigateTo(homepagePath)
    }

    // has refresh token
    if (!loginCookie.value && !!refreshTokenCookie.value) {
      return navigateTo(refreshPath)
    }
    // has login cookie
    if (!!loginCookie.value) {
      return
    }

    // else navigate to login page
    if (to.fullPath !== loginPath) {
      return navigateTo(loginPath)
    }
    return
  }

  if (!!loginCookie.value || !!refreshTokenCookie.value) {
    await oidc.fetchUser()
    if (isLoggedInValid()) {
      return
    } else {
      return navigateTo(loginPath)
    }
  }

  return
})
