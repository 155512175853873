export const useDate = () => {
  const runtimeConfig = useRuntimeConfig()
  const days = runtimeConfig.public.numberOfDaysInFeed || 0
  return {
    todayString: () => {
      const now = new Date()
      now.setHours(0)
      now.setMinutes(0)
      now.setSeconds(0)
      now.setUTCDate(now.getUTCDate() - days)
      return now.toISOString()
    },
    getPreciseTime: () => {
      const now = new Date()
      return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`
    }
  }
}
