<template>
  <NuxtLayout>
    <NuxtPage/>
    <UNotifications/>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
const colorMode = useColorMode();

// Store mouse position
const mouseX = ref(0);
const mouseY = ref(0);

// Function to handle mouse click and store position
const handleMouseClick = (event: MouseEvent) => {
  mouseX.value = event.clientX;
  mouseY.value = event.clientY;

  // Update CSS variables with mouse position
  document.documentElement.style.setProperty('--mouse-x', `${mouseX.value}px`);
  document.documentElement.style.setProperty('--mouse-y', `${mouseY.value}px`);
};

// Add event listener for capturing mouse clicks
onMounted(async () => {
  await nextTick();
  colorMode.preference = "light";
  document.addEventListener('click', handleMouseClick);
});

// Clean up event listener when component is unmounted
onUnmounted(() => {
  document.removeEventListener('click', handleMouseClick);
});
</script>

<style lang="scss">

.page-leave-active,
.page-enter-active {
  transition: all 0.4s ease-in-out;


  &:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 0;
    z-index: 50;
    transform-origin: var(--mouse-x) var(--mouse-y);
    transform: scale(0);
    transition: opacity 0.4s ease-in-out,
    transform 0.4s ease-in-out;
  }
}

.page-leave-to {
  &:after {
    opacity: 1;
    transform: scale(1);
  }
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.5rem);
}


///////
</style>