export default defineNuxtRouteMiddleware(async (to, from) => {
  const oidc = useOidc()
  const logger = useFrontendLogger()

  if (oidc.isLoggedIn) {
    logger.addMeta('user', oidc.user)
  }

  return
})
