import * as Sentry from "@sentry/vue";

enum Severity {
  Debug = 'debug',
  Info = 'info',
  Log = 'log',
  Warning = 'warning',
  Error = 'error',
  Fatal = 'fatal'
}
export default class FrontendLogger {

  meta: Record<string, any> = {}
  tags: Record<string, any> = {}

  constructor(parent?: FrontendLogger) {
    if (parent) {
      this.meta = {...parent.meta}
      this.tags = {...parent.tags}
    }
  }

  addMeta(key: string, value: any) {
    this.meta[key] = value
  }

  addTag(key: string, value: string): FrontendLogger {
    const newLogged = new FrontendLogger(this)
    newLogged.tags[key] = value
    return newLogged
  }

  debug(msg: string | object | Error, ...args: any[]) {
    console.debug(msg, args)

    this.sentryLog({
      args,
      msg,
      type: Severity.Debug,
    })
  }

  error(msg: string | object | Error, ...args: any[]) {
    console.debug(msg, args)

    this.sentryLog({
      args,
      msg,
      type: Severity.Error,
    })
  }

  fatal(msg: string | object | Error, ...args: any[]) {
    console.error(msg, args)

    this.sentryLog({
      args,
      msg,
      type: Severity.Fatal,
    })
  }

  info(msg: string | object | Error, ...args: any[]) {
    console.info(msg, args)

    this.sentryLog({
      args,
      msg,
      type: Severity.Info,
    })
  }

  trace(msg: string | object | Error, ...args: any[]) {
    console.trace(msg, args)

    this.sentryLog({
      args,
      msg,
      type: Severity.Info,
    })
  }

  warn(msg: string | object | Error, ...args: any[]) {
    console.warn(msg, args)

    this.sentryLog({
      args,
      msg,
      type: Severity.Warning,
    })
  }

  sentryLog(log: {
    msg: string | object | Error
    args: any[]
    type: Severity
  }): void {
    const { msg } = log
    Sentry &&
    Sentry.withScope((scope) => {
      // const logSeverity = SEVERITY_LEVELS[log.type]
      // if (logSeverity < SEVERITY_LEVELS[Severity.Warning]) {
      //   return
      // }
      for (let prop in this.tags) {
        if (this.tags.hasOwnProperty(prop)) {
          scope.setTag(String(prop), String(this.tags[prop]))
        }
      }

      scope.setExtra('args', log.args)
      scope.setExtra('meta', this.meta)
      scope.setExtra('flattenLog', this.flattenObject(log))

      if (msg.constructor === Error) {
        return Sentry.captureException(msg)
      }

      Sentry.captureMessage(log.msg as string, log.type)
    })
  }

  flattenObject(ob: any) {
    try {
      const toReturn: Record<string, object> = {}
      for (const iteratorOb in ob) {
        // eslint-disable-next-line no-prototype-builtins
        if (!ob.hasOwnProperty(iteratorOb)) {
          continue
        }
        if (typeof ob[iteratorOb] === 'object' && ob[iteratorOb] !== null) {
          const flatObject = this.flattenObject(ob[iteratorOb])
          for (const iteratorObFlat in flatObject) {
            // eslint-disable-next-line no-prototype-builtins
            if (!flatObject.hasOwnProperty(iteratorObFlat)) {
              continue
            }
            toReturn[`${iteratorOb}.${iteratorObFlat}`] =
              flatObject[iteratorObFlat]
          }
        } else {
          toReturn[iteratorOb] = ob[iteratorOb]
        }
      }
      return toReturn
    } catch (e) {
      return null
    }
  }
}