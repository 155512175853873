<template>
  <Transition>
    <div
      v-if="article && article.isVisible"
      class="article-card relative min-w-44 w-full col-span-1 flex flex-col rounded-2xl bg-white text-black"
      :class="{'md:col-span-2' : shouldExpand}"
    >

      <ArticlesTrending v-if="trending" :trending="article.trendingOrder"/>
      <ArticlesPodcastBadge v-else-if="isPodcast()"/>

      <NuxtLink
        :to="`${routes.articles.path}/${useShortItemId(article.id)}${getLinkParams()}`"
        :class="!!trending ? '-mt-px rounded-b-2xl' : '-mt-px rounded-2xl'"
        class="article-card__aside overflow-hidden w-full max-h-64 sm:max-h-52"
      >
        <ArticlesPreviewImage
          :src="article?.previewAsset?.urlWithProtocol || article?.articleLinkAssetList?.edges?.at(0)?.node?.asset?.path"
          :alt="article.title"
          class="article-card__img"
        />
      </NuxtLink>

      <div class="flex-1 flex flex-col items-start gap-3 lg:gap-6 px-4 py-3">

        <!--no article photo -->
        <!--				<svg class="mt-7" xmlns="http://www.w3.org/2000/svg" width="44" height="30" viewBox="0 0 44 30" fill="none">-->
        <!--					<path d="M17.9 11.2C16.4333 11.2 15.2667 11.7 14.4 12.7C13.6 13.7 13.2 15.0667 13.2 16.8V29.3H0V15.5C0 11.0333 1.33333 7.43333 4 4.7C6.66667 1.9 10.1333 0.5 14.4 0.5H19.9V11.2H17.9ZM23.6 15.5C23.6 11.0333 24.9333 7.43333 27.6 4.7C30.2667 1.9 33.7333 0.5 38 0.5H43.5V11.2H41.5C40.0333 11.2 38.8667 11.7 38 12.7C37.2 13.7 36.8 15.0667 36.8 16.8V29.3H23.6V15.5Z" fill="black"/>-->
        <!--				</svg>-->
        <!--no article photo -->

        <ArticlesAuthorBadge :author="article?.simpleAuthor" />

        <div class="article-card__title font-bold text-h16/tight md:text-h20/tight 2xl:text-h24/tight">
          <NuxtLink :to="`${routes.articles.path}/${useShortItemId(article.id)}${getLinkParams()}`">
            {{ article.title }}
          </NuxtLink>
        </div>

        <div class="flex justify-between items-center w-full mt-auto">
          <ArticlesPodcastRemaining v-if="isPodcast()" :remaining="5"/>

          <!--read-time and publisher-->
          <div class="flex gap-0.5 items-center text-xs text-neutral-600 lg:text-black">
            <div v-if="getReadingTime()" class="font-bold uppercase">{{ getReadingTime() }} min</div>
            <span v-if="getReadingTime()" class="w-2 h-px bg-neutral-600 lg:bg-black"></span>
            <div class="font-extralight">{{ article.magazine.name }}</div>
          </div>
          <!--read-time and publisher-->

          <ArticlesReaderButton
            :id="article.id"
            :is-reader="isReader"
          />
        </div>
        <!--				<div class="px-6 py-4">-->
        <!--					<span class="badge mr-2"><NuxtLink to="/about">About</NuxtLink></span>-->
        <!--				</div>-->
      </div>

      <!-- FOR ARTICLE DEBUGGING -->
      <div
        v-if="debugCookie"
        class="w-8 h-8 absolute right-2 top-2 bg-black opacity-75 rounded-full cursor-pointer"
        @click="isDebugModalOpen = !isDebugModalOpen"
      >
        <UIcon name="i-heroicons-light-bulb" class="w-4 h-4 fill-white opacity-100 text-white mt-2 ml-2" />
      </div>

      <UModal v-model="isDebugModalOpen">
        <div class="p-4">
          <h5>Published</h5>
          <span>{{ article.publishedDate }}</span>

          <h5>ID</h5>
          <span>{{ article.id }}</span>

          <h5 class="pb-1 mt-3">AI Topics</h5>
          <ul class="mt-0">
            <li v-for="c in article.aiTopicList?.edges" class="m-0 pb-0">
              {{ c?.node?.name }}: {{ c?.node?.id }}
            </li>
          </ul>

          <h5 class="pb-1 mt-3">Categories</h5>
          <ul class="mt-0">
            <li v-for="c in article.categoryList?.edges" class="m-0 pb-0">
              {{ c?.node?.name }}: {{ c?.node?.id }}
            </li>
          </ul>
        </div>
      </UModal>
      <!-- /FOR ARTICLE DEBUGGING -->

    </div>
  </Transition>
</template>

<script setup lang="ts">
import routes from "~/libs/configs/routes"
import {useShortItemId} from "~/composables/useShortItemId"
import type ArticleLocal from "~/libs/interfaces/ArticleLocal"
import type {PropType} from "vue"
import {DEBUG_COOKIE} from "~/libs/configs/constants"

const {article, trending, shouldExpand, isReader, prevPage} = defineProps({
  article: {type: Object as PropType<ArticleLocal>, required: false},
  trending: {type: Boolean, required: false, default: false},
  isReader: {type: Boolean, required: false, default: false},
  shouldExpand: {type: Boolean, required: false},
  prevPage: {type: String, required: false},
})

const debugCookie = useCookie(DEBUG_COOKIE)

const isDebugModalOpen = ref(false)

const getLinkParams = (): string => {
  return prevPage ? `?prevPage=${prevPage}` : ''
}

const isPodcast = (): boolean => {
  return false
}

const getReadingTime = (): Number | undefined => {
  return article?.readingTime ? Number(article?.readingTime) : undefined
}
</script>

<style lang="postcss" scoped>

.article-card, .article-card__img {
  transition: 420ms all var(--easing-cubic);
}

.article-card__aside, .article-card__title {
  cursor: pointer;
}

.article-card:hover {
  transform: scale(1.02) translateY(-1%);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.05), -2px 0px 20px 0px rgba(0, 0, 0, 0.05);
}

.article-card:hover .article-card__img {
  transform: scale(1.15);
}
.v-enter-active,
.v-leave-active {
  @apply transition-all duration-100 opacity-100
}

.v-enter-from,
.v-leave-to {
  @apply transition-all duration-100 opacity-0
}
</style>
