import * as Sentry from "@sentry/vue";
import type SentryConfig from "~/libs/interfaces/SentryConfig";

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!process.client || process.dev) return

  // lazy load sentry replay
  Sentry.addIntegration(Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }))
}
function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return [];

  const router = useRouter();
  const browserTracing = Sentry.browserTracingIntegration({
    router
  })
  return [browserTracing];
}



export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    const config = useRuntimeConfig();
    const {
      dsn,
      environment,
      tracesSampleRate,
      replaySampleRate,
      errorReplaySampleRate
    }: SentryConfig = config.public.sentry

    if (!dsn) {
      console.warn('Sentry DSN not set, skipping Sentry initialization')
    }

    Sentry.init({
      app: vueApp,
      dsn: dsn ?? null,
      integrations: getSentryIntegrations(),
      environment: environment || 'production',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: tracesSampleRate || 0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      tracePropagationTargets: ['*'],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: replaySampleRate || 0,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: errorReplaySampleRate || 0,
    })

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations()

    console.log('Sentry loaded')
  }
});