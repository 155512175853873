<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 13 13">
    <path
      d="M10.1719 11.1987V10.1987C10.1719 9.6683 9.96116 9.15959 9.58609 8.78452C9.21102 8.40944 8.70231 8.19873 8.17188 8.19873H4.17188C3.64144 8.19873 3.13273 8.40944 2.75766 8.78452C2.38259 9.15959 2.17188 9.6683 2.17188 10.1987V11.1987"
      stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M6.17188 6.19873C7.27644 6.19873 8.17188 5.3033 8.17188 4.19873C8.17188 3.09416 7.27644 2.19873 6.17188 2.19873C5.06731 2.19873 4.17188 3.09416 4.17188 4.19873C4.17188 5.3033 5.06731 6.19873 6.17188 6.19873Z"
      stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>