<template>
  <div class="footer py-8 lg:py-20">
    <div class="mx-auto max-w-screen-xl px-5 sm:px-8">
      <div
        class="text-base font-normal flex flex-col sm:flex-row gap-8 justify-around items-start">
        <div class="flex-1 flex flex-col sm:flex-row sm:justify-center gap-4 lg:gap-8">
          <a rel="nofollow" target="_blank" href="https://www.cncenter.cz/autorska-prava">{{ $t('footer.link_copyright') }}</a>
          <a rel="nofollow" target="_blank" href="https://www.cncenter.cz/cookies">{{ $t('footer.link_cookies') }}</a>
          <NuxtLink to="/about">{{ $t('footer.link_privacy_settings') }}</NuxtLink>
          <a rel="nofollow" target="_blank" href="https://www.cncenter.cz/uzivani-sluzby">{{ $t('footer.link_tnc_directory') }}</a>
        </div>
      </div>
      <div class="mt-12 mb-12 flex flex-col sm:flex-row gap-8 justify-center">
          <IconsCncLogo />
      </div>
      <div
        class="font-regular text-sm sm:text-base flex items-center justify-start sm:justify-center gap-1">
        <p>&copy; {{ new Date().getFullYear() }} {{ $t('footer.copyright_text') }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.footer {
  @apply w-auto bg-black text-white;
}
</style>
<script setup lang="ts">
</script>
