import type ArticleLocal from "~/libs/interfaces/ArticleLocal"

export const useArticleMagazineIdSearch = (
  article?: ArticleLocal,
  ids?: string[],
): boolean => {
  if (!ids || ids.length === 0 || !article) {
    return true
  }

  // filter selected media
  return ids.includes(article.magazine?.id)
}
