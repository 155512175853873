import type CategoryLocal from "~/libs/interfaces/CategoryLocal";
import type AiTopicLocal from "~/libs/interfaces/AiTopicLocal";

export enum PersistedStoreType {
  FEED = "feed",
  READER = "reader",
  TRENDING = "trending",
  MORE = "more",
  SHORT_ARTICLES = "short_articles",
}
export const usePersistedStore = defineStore('myfeed-persisted', {
  state: () => {
    const state: {
      feedFulltext: Ref<string>,
      feedSearchActive: Ref<boolean>,
      feedSelectedCategories: String[],
      feedLastSync: Date | null,

      moreFulltext: Ref<string>,
      moreSearchActive: Ref<boolean>,

      readerFulltext: Ref<string>,
      readerSearchActive: Ref<boolean>,
      readerSelectedCategories: String[],
      readerLastSync: Date | null,

      trendingFulltext: Ref<string>,
      trendingSearchActive: Ref<boolean>,
      trendingSelectedCategories: String[],
      trendingLastSync: Date | null,

      shortArticlesLastSync: Date | null,

      showMenu: Ref<boolean>,
    } = {
      feedFulltext: ref(''),
      feedSearchActive: ref(false),
      feedSelectedCategories: reactive([]),
      feedLastSync: null,
      moreFulltext: ref(''),
      moreSearchActive: ref(false),
      readerFulltext: ref(''),
      readerSearchActive: ref(false),
      readerSelectedCategories: reactive([]),
      readerLastSync: null,
      trendingFulltext: ref(''),
      trendingSearchActive: ref(false),
      trendingSelectedCategories: reactive([]),
      trendingLastSync: null,
      shortArticlesLastSync: null,
      showMenu: ref(true),
    }
    return state
  },
  getters: {
    getIsFeedFulltextActive(state): boolean {
      if (state.feedFulltext && state.feedFulltext.length > 0) {
        return true
      }
      return false
    },
    getIsMoreFulltextActive(state): boolean {
      if (state.moreFulltext && state.moreFulltext.length > 0) {
        return true
      }
      return false
    },
    getIsTrendingFulltextActive(state): boolean {
      if (state.trendingFulltext && state.trendingFulltext.length > 0) {
        return true
      }
      return false
    },
    getIsReaderFulltextActive(state): boolean {
      if (state.readerFulltext && state.readerFulltext.length > 0) {
        return true
      }
      return false
    },
  },
  actions: {
    resetFilters(type: PersistedStoreType): void {
      switch (type) {
        case PersistedStoreType.FEED:
          this.feedFulltext = ''
          this.feedSearchActive = false
          this.feedSelectedCategories = []
          break
        case PersistedStoreType.TRENDING:
          this.trendingFulltext = ''
          this.trendingSearchActive = false
          this.trendingSelectedCategories = []
          break
        case PersistedStoreType.READER:
          this.readerFulltext = ''
          this.readerSearchActive = false
          this.readerSelectedCategories = []
          break
        case PersistedStoreType.MORE:
          this.moreFulltext = ''
          this.moreSearchActive = false
          break
        default:
          return
      }
    },
    // cleanup selected categories which are not listed on page
    cleanUpSelectedCategories(type: PersistedStoreType, availableCategories: CategoryLocal[] | AiTopicLocal[]): void {
      let selectedCategories
      switch (type) {
        case PersistedStoreType.FEED:
          selectedCategories = this.feedSelectedCategories
          break
        case PersistedStoreType.TRENDING:
          selectedCategories = this.trendingSelectedCategories
          break
        case PersistedStoreType.READER:
          selectedCategories = this.readerSelectedCategories
          break
        default:
          return
      }
      if (selectedCategories && selectedCategories.length > 0) {
        const categoryIds = availableCategories.map((category) => {
          return category.id
        })

        for (let i = 0; i < selectedCategories.length; i++) {
          const selectedCategory = selectedCategories[i];
          if (!categoryIds.includes(selectedCategory as string)) {
            selectedCategories.splice(i, 1);
            i--; //decrement
          }
        }
      }
    },
    needSync(type: PersistedStoreType): boolean {
      const config = useRuntimeConfig()
      let lastSync
      let syncInterval: number = 1000 * 60 * 5
      switch (type) {
        case PersistedStoreType.FEED:
          lastSync = this.feedLastSync ? new Date(this.feedLastSync) : undefined
          syncInterval = Number(config.public.feedSyncInterval)
          break
        case PersistedStoreType.TRENDING:
          lastSync = this.trendingLastSync ? new Date(this.trendingLastSync) : undefined
          syncInterval = Number(config.public.trendingSyncInterval)
          break
        case PersistedStoreType.READER:
          lastSync = this.readerLastSync ? new Date(this.readerLastSync) : undefined
          syncInterval = Number(config.public.readerSyncInterval)
          break
        case PersistedStoreType.SHORT_ARTICLES:
          lastSync = this.shortArticlesLastSync ? new Date(this.shortArticlesLastSync) : undefined
          syncInterval = Number(config.public.shortArticlesSyncInterval)
          break
        default:
          return true
      }

      if (syncInterval === 0) {
        return false
      }

      const nextSync = new Date()
      nextSync.setTime(nextSync.getTime() - syncInterval)

      if (!lastSync || lastSync.getTime() < nextSync.getTime()) {
        return true
      }

      return false
    },
    setLastSync(type: PersistedStoreType): void {
      switch (type) {
        case PersistedStoreType.FEED:
          this.feedLastSync = new Date()
          break
        case PersistedStoreType.TRENDING:
          this.trendingLastSync = new Date()
          break
        case PersistedStoreType.READER:
          this.readerLastSync = new Date()
          break
        case PersistedStoreType.SHORT_ARTICLES:
          this.shortArticlesLastSync = new Date()
          break
      }
    },
    resetLastSync(type: PersistedStoreType): void {
      switch (type) {
        case PersistedStoreType.FEED:
          this.feedLastSync = null
          break
        case PersistedStoreType.TRENDING:
          this.trendingLastSync = null
          break
        case PersistedStoreType.READER:
          this.readerLastSync = null
          break
        case PersistedStoreType.SHORT_ARTICLES:
          this.shortArticlesLastSync = null
          break
      }
    },
    resetLastSyncAll(): void {
      this.resetLastSync(PersistedStoreType.FEED)
      this.resetLastSync(PersistedStoreType.TRENDING)
      this.resetLastSync(PersistedStoreType.READER)
      this.resetLastSync(PersistedStoreType.SHORT_ARTICLES)
    },
    cancelSearch(type: PersistedStoreType): void {
      this.clearSearch(type)
      switch (type) {
        case PersistedStoreType.FEED:
          this.feedSearchActive = false
          break
        case PersistedStoreType.TRENDING:
          this.trendingSearchActive = false
          break
        case PersistedStoreType.READER:
          this.readerSearchActive = false
          break
        case PersistedStoreType.MORE:
          this.moreSearchActive = false
          break
      }
    },
    clearSearch(type: PersistedStoreType): void {
      switch (type) {
        case PersistedStoreType.FEED:
          this.feedFulltext = ''
          break
        case PersistedStoreType.TRENDING:
          this.trendingFulltext = ''
          break
        case PersistedStoreType.READER:
          this.readerFulltext = ''
          break
        case PersistedStoreType.MORE:
          this.moreFulltext = ''
          break
      }
    },
    openSearch(type: PersistedStoreType): void {
      switch (type) {
        case PersistedStoreType.FEED:
          this.feedSearchActive = true
          break
        case PersistedStoreType.TRENDING:
          this.trendingSearchActive = true
          break
        case PersistedStoreType.READER:
          this.readerSearchActive = true
          break
        case PersistedStoreType.MORE:
          this.moreSearchActive = true
          break
      }
    },
  },
  persist: true,
})
