export const useCustomToast = () => {
  const toast = useToast();
  return {
  add(notification: Partial<Notification>) {
    toast.clear()
    toast.add({...notification, icon: 'i-heroicons-check-circle',})
  },
  remove(notification: Partial<Notification>) {
    toast.clear()
    toast.add({...notification, icon: 'i-heroicons-x-circle',})
  },
}};
