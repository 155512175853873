import { ULIDtoUUID } from "ulid-uuid-converter"
import {useShortItemId} from "~/composables/useShortItemId"

const logger = useFrontendLogger()
export const useApiIdConverter = () => {

  const apiIdToUuid = (id?: string) => {
    if (!id) {
      return undefined
    }
    try {
      const uuid = useShortItemId(id)
      return ULIDtoUUID(uuid)
    } catch (e) {
      logger.error('Error useApiIdConverter.apiIdToUuid', {
        id,
        e
      })
      return undefined
    }
  }
  return {
    apiIdToUuid
  }
}