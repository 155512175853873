<template>
  <div
    v-if="props.articles"
    :class="getTypeClass(props.type)"
    :data-articles-count="articlesCount()"
    :data-expand="props.expand"
  >
    <ArticlesArticleCard
      v-for="(article, index) in props.articles" :article="article"
      :key="`article_${article.id}`"
      :trending="props.isTrending"
      :should-expand="shouldExpand(index)"
      :is-reader="props.isReader"
      :data-index="index"
      :prev-page="prevPage"
    />
    <ArticlesNoMoreArticlesArticleCard v-if="!props.hideNoMoreArticles && (isMobile && articlesCount() < 1 || !isMobile && articlesCount() < 2)" />
  </div>
</template>

<script setup lang="ts">
import { type PropType } from "vue"
import type ArticleLocal from "~/libs/interfaces/ArticleLocal"
import CategoryListingType from "~/libs/interfaces/CategoryListingType"
import ExpandArticle from "~/libs/interfaces/ExpandArticle";

// !! use props without decomposition - otherwise reactivity will be lost and watchers will not be working
const props = defineProps({
  expand: { type: String as PropType<ExpandArticle>, required: false, default: ExpandArticle.LEFT },
  hideNoMoreArticles: {type: Boolean, required: false},
  isTrending: {type: Boolean, required: false, default: false},
  isReader: {type: Boolean, required: false, default: false},
  articles: {type: Array as PropType<ArticleLocal[]>, required: true},
  type: {type: String as PropType<CategoryListingType>, required: true},
  prevPage: {type: String, required: false},
})

const articlesCount = (): number => {
  return props.articles?.filter(a => a.isVisible).length
}

const getVisibleIndex = (index: number): number => {
  let visibleIndex = -1
  for (let i = 0; i < props.articles.length; i++) {
    if (props.articles[i].isVisible) {
      visibleIndex = visibleIndex + 1
    }
    if (i >= index) {
      break
    }
  }
  return visibleIndex
}

const shouldExpand = (index: number): boolean => {
  return false
  const visibleIndex = getVisibleIndex(index)
  return (props.expand !== ExpandArticle.NO &&
    (
      articlesCount() == 1 || articlesCount() == 2 &&
      (
        props.expand == ExpandArticle.LEFT && visibleIndex === 0 ||
        props.expand == ExpandArticle.RIGHT && visibleIndex === 1
      )
    ))
}

const getTypeClass = (type: CategoryListingType) => {
  switch (type) {
    case CategoryListingType.CATEGORY:
      return "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 gap-x-5 lg:gap-x-8"
    case CategoryListingType.CAROUSEL:
      return '-mx-5 px-5 sm:px-0 sm:mx-0 flex overflow-x-scroll sm:overflow-x-visible scrollbar-hide sm:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 gap-x-3 sm:gap-x-5 lg:gap-x-8'
    case CategoryListingType.ONECOL:
      return "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 gap-x-5 lg:gap-x-8"
    case CategoryListingType.TWOCOL:
      return "-mx-10 px-10 sm:px-0 sm:mx-0 grid grid-cols-2 gap-8 gap-x-3 sm:gap-x-5 lg:gap-x-8"
    case CategoryListingType.MOREARTICLES:
      return "more-articles -mx-5 px-5 sm:px-0 sm:mx-0 flex overflow-x-scroll sm:overflow-x-visible scrollbar-hide sm:grid sm:grid-cols-2 gap-8 gap-x-3 sm:gap-x-5 lg:gap-x-8"
  }
}

const isMobile = ref(false)
const checkIfMobileBreakpoint = () => {
  isMobile.value = window && window.innerWidth < 770
}

if (window) {
  onMounted(checkIfMobileBreakpoint)
  window.addEventListener('resize', checkIfMobileBreakpoint)
}

</script>
