export enum MyApiEndpoint {
  ARTICLES = "articles",
  AUTHORS = "authors",
  CATEGORIES = "categories",
  MEDIA = "media",
}

export enum MyApiStoreType {
  ARTICLES = "my_articles",
  AUTHORS = "my_authors",
  CATEGORIES = "my_categories",
  MEDIA = "my_media",
}

export const endpointToStoreType = (endpoint: MyApiEndpoint):MyApiStoreType => {
  switch (endpoint) {
    case MyApiEndpoint.ARTICLES:
      return MyApiStoreType.ARTICLES
    case MyApiEndpoint.AUTHORS:
      return MyApiStoreType.AUTHORS
    case MyApiEndpoint.CATEGORIES:
      return MyApiStoreType.CATEGORIES
    case MyApiEndpoint.MEDIA:
      return MyApiStoreType.MEDIA
  }
}
