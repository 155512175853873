import type ArticleLocal from "~/libs/interfaces/ArticleLocal";
import type {Article, AuthorEdge} from "~/graphql/__generated__/graphql";
import {useHtmlToPlainText} from "~/composables/useHtmlToPlainText";

const articleLocalFactory = {
  createArticleLocal(article: Article, useAiArticles: boolean = false): ArticleLocal {
    const aiAuthor = article?.aiAuthorList?.edges?.at(0)?.node
    const author = article?.authorList?.edges?.at(0)?.node
    return {
      ...article,
      ...(useAiArticles && aiAuthor && article.hasAiValues && {
        simpleAuthor: {
          id: aiAuthor.id as string,
          name: aiAuthor.name as string,
          ...(aiAuthor.imageAsset?.urlWithProtocol && {
            imageUrlWithProtocol: aiAuthor.imageAsset?.urlWithProtocol as string
          }),
        }
      }),
      ...((!useAiArticles || !aiAuthor) && author && {
        simpleAuthor: {
          id: author.id as string,
          name: author.fullname as string,
          ...(author.imageAsset?.urlWithProtocol && {
            imageUrlWithProtocol: author.imageAsset?.urlWithProtocol as string
          }),
        },
      }),
      isVisible: true,
      ...(article.published && {
        publishedDate: new Date(article.published)
      }),
      ...(article.jsonBody && {
        jsonBodyDecoded: JSON.parse(article.jsonBody)
      }),
      ...(article.body && {
        bodyPlain: useHtmlToPlainText(article.body)
      })
    }
  }
}
export default articleLocalFactory
